@import 'src/theme.scss';

// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600&display=swap');

@font-face {
	font-family: 'Manifold';
	src: url('assets/WorkSans-Regular.ttf');
  }
  
  @font-face {
	font-family: 'Work_Sans';
	src: url('assets/WorkSans-VariableFont_wght.ttf');
  }
  @font-face {
  	font-family: 'MyriadPro';
  	src: url('assets/MyriadPro-Regular.otf');
  }
html, body {
	height: 100%;
	width: 100%;
	margin: 0;
	overflow-x: hidden;
	font-family: 'Work_Sans' !important;
	background-color: mat-color($background, lighter);
}

*:focus {
	outline: none;
}

.clickable {
	cursor: pointer;
}


h1,h2,h3,h4,h5 ,.title {
	font-family: 'Manifold' !important;
	text-transform: uppercase !important;
}

.text {
	font-family: 'Work_Sans' !important;
	font-weight: 400;
}
.mat-toolbar, .mat-form-field, .mat-card {
	font-family: 'Work_Sans' !important;
}
.empty-text {
	font-family: 'Work_Sans' !important;
	font-weight: 400;
	width: 100%;
	text-align: center;
}

.empty-image {
	display: block;
	width: 80%;
	max-width: 360px;
	height: auto;
}

.full {
	min-width: 100vw;
	min-height: 100vh;
}

.full-width {
	width: 100%;
}

.half-width {
	width: 50%;
}

.full-height {
	height: 100%;
}

.info-tooltip {
	white-space: pre-line;
}

.error {
	color: mat-color($warn);
}


div.home:not(.xs) {
	position: relative;
	min-height: 100vh;
	width: 100%;
	background-color: mat-color($background, 100);
	background-image: url('assets/images/flechas-20.svg');
	// background-size: auto 50%;
	background-repeat: no-repeat;
	// background-position: right bottom;
	background-attachment: fixed;
}

div.home.xs {
	position: relative;
	min-height: 100vh;
	width: 100vw;
	// background-color: white !important;
}


div.home div.content {
	width: 100%;
	max-width: 1242px;
}


span.footer {
	color: mat-color($primary, 400);
	font-weight: 600;
	font-size: 0.9em;
	padding: 0.75em;
	width: 100%;
	text-align: center;
}

span.footer.primary {
	background-color: mat-color($primary);
	color: white;
}


table {
	width: 100%;
	font-family: 'Work_Sans' !important;
	font-weight: 400;
}

tr.mat-row:not(.element-row):nth-child(2n - 1) {
	background-color: mat-color($background, lighter);
}

tr.mat-row.element-row:nth-child(4n - 1) {
	background-color: mat-color($background, lighter);
}

td.mat-cell > * {
	vertical-align : middle !important;
}

td.mat-cell mat-icon {
	height: auto !important;
	width: auto !important;
	font-size: 1.3em !important;
}

mat-card.table-card {
	padding: 0;
	overflow: hidden;
	height: auto !important;
}

mat-card.list-card {
	padding: 0.5em 0;
	overflow: hidden;
}


button.mat-icon-button app-icon {
	display: inline-block;
	height: 24px;
}


div.spinner-container {
	height: 60px !important;
	min-width: calc(60px + 4em) !important;
	margin: 2em 0 !important;
}



button {
	font-family: 'Work_Sans' !important;
	font-weight: 600 !important;
}

button.mat-fab.mat-primary {
	background-color: mat-color($primary);
}

div.panel {
	border-radius: 20px !important;
	background-color: transparent;
}





button.mat-fab {
	background-color: mat-color($background, 700) !important;
	color: white !important;
	border: solid 1px mat-color($primary) !important;
}

button.mat-fab > span {
	margin-top: -2px;
}



span.link {
	font-weight: 600;
	color: mat-color($accent);
	cursor: pointer;
}


mat-form-field {
	margin-bottom: 0px;
}

mat-form-field.margin-top {
	margin-top: 1.5em !important;
}

mat-form-field input {
	border: none !important;
}



mat-form-field[appearance="outline"] div.mat-form-field-outline-start,
mat-form-field[appearance="outline"] div.mat-form-field-outline-gap,
mat-form-field[appearance="outline"] div.mat-form-field-outline-end {
	border-color: white !important;
}

mat-form-field[appearance="outline"] div.mat-form-field-infix {
	padding: 0 !important;
	padding-top: 0.5em !important;
    padding-bottom: 0.5em !important;
	border-top: 0.4em solid transparent !important;
    border-bottom: 0.4em solid transparent !important;
}

mat-form-field[appearance="outline"] div.mat-form-field-wrapper {
	padding: 0 !important;
}

mat-form-field[appearance="outline"] .mat-form-field-prefix {
	margin-right: 10px !important;
	top: 0.5em !important;
}

mat-form-field[appearance="outline"] .mat-form-field-suffix {
	margin-left: 10px !important;
	top: 0.5em !important;
}

mat-form-field[appearance="outline"].button-suffix .mat-form-field-suffix {
	margin-left: 0px !important;
	top: 0px !important;
}

mat-form-field[appearance="outline"] .mat-select-arrow-wrapper {
	display: block !important;
}


mat-form-field[appearance="outline"] .mat-form-field-subscript-wrapper {
	bottom: -1.25em !important;
	top: unset !important;
	padding: 0 5px !important;
}


mat-form-field[appearance="outline"].primary div.mat-form-field-outline-start,
mat-form-field[appearance="outline"].primary div.mat-form-field-outline-gap,
mat-form-field[appearance="outline"].primary div.mat-form-field-outline-end {
	border-color: mat-color($primary) !important;
}


mat-form-field[appearance="outline"].mat-form-field-should-float div.mat-form-field-outline-gap {
	border-top-color: transparent !important;
}

mat-form-field[appearance="outline"].force-prefix div.mat-form-field-outline-start {
	width: 40px !important;
}


button.mat-flat-button {
	border-radius: 0px;
}
.mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-radius: 0 0px 0px 0 !important;
}
.mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-radius: 0px 0 0 0px !important;
}
img.icon-xs {
	height: 16px;
	width: 16px;
	display: block;
}

img.icon-sm {
	height: 24px;
	width: 24px;
	display: block;
}



a {
	color: mat-color($primary);
	cursor: pointer;
	text-decoration: none;
	font-weight: 600 !important;
}

a:hover {
	color: mat-color($primary, darker);
	cursor: pointer;
	text-decoration: none;
	font-weight: 600 !important;
	opacity: 0.8;
}



input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  transition: background-color 5000s;
  -webkit-text-fill-color: #fff !important;
}
.mat-header-cell {
	background-color: #000 !important;
    color: #ffffff !important;
	.mat-sort-header-arrow {
		color: #f4eded !important;
	}
}


.redChip {
	color: #ffffff;
    background-color: #e20505;
    border: 1px solid #aba2a2;
    padding: 2px 9px;
    // border-radius: 13px;
}
.orangeChip {
	color: #ffffff;
    background-color: #FFA500;
    border: 1px solid #aba2a2;
    padding: 2px 9px;
    // border-radius: 13px;
}
.blueChip {
	color: #ffffff;
    background-color: #0000ff;
    border: 1px solid #aba2a2;
    padding: 2px 9px;
    // border-radius: 13px;
}
.greyChip {
	color: #ffffff;
    background-color: #808080;
    border: 1px solid #aba2a2;
    padding: 2px 9px;
    // border-radius: 13px;
}
.greenChip {
	color: #ffffff;
    background-color: #0aa416;
    border: 1px solid #aba2a2;
    padding: 2px 9px;
    // border-radius: 13px;
}
.multi_arrow {
	height: 17px;
margin-right: 5px; 
}
.hamburger-icon{
	width: 24px !important;
	height: 24px !important;
}

	mat-expansion-panel {
	  box-shadow: none !important;
	  background-color: #ea0029 !important;
	  color: white !important;
  
	  mat-expansion-panel-header {
		height: auto !important;
		font-size: 9px !important;
		padding: 10px 16px !important;
		align-items: center;
  
		panel-icon {
		  width: 6px;
		  height: 6px;
		  margin-right: 15px;
		}
	  }
  
	  mat-expansion-panel-header-title {
		color: white !important;
		align-items: center;
	  }
	}
  
	mat-expansion-panel-header-description,
	mat-expansion-indicator::after {
	  color: white !important;
	}
  
	mat-expansion-indicator::after {
	  padding: 2px !important;
	}
  
	mat-expansion-panel-body {
	  padding: 0 14px !important;
	}

.sidenav-container,
.main-container {
	height: 100%;

}
.mat-drawer {
	background-color: #ea0029 !important;
}
.mat-drawer-inner-container{
	&::-webkit-scrollbar {
			width: 5px;
		}
	
	
		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: rgba(255, 255, 255, 0.87);
				
			border-radius: 5px;
		}
	
		
}
.left-sidenav-content{

	padding: 25px 30px;

	// &.content-shrink {
	// 	margin-left: 260px !important;
	// }
	.custum-card{
		filter: drop-shadow(0px 0px 8px transparent);
		box-shadow: none !important;

		@media screen and (max-width: 768px) {
			padding: 30px 12px 0;
		}
	}

	@media screen and (max-width: 768px) {
		padding: 25px 12px;
	}
}

.menu-links{
	.mat-list-base{
		padding-top: 0;

		.mat-list-item{
			height: auto;
			padding: 6px 0;
			color: #ffffff !important;
			font-size: 12px !important;
			line-height: 18px;
			border-bottom: 0.75px solid rgba(255,255,255,0.4);
			font-family: 'Manifold';
			font-weight: bold;
			margin-bottom: 15px;
			img{
				width: 12px;
				height: 12px;
				margin-right: 12px;
			}

			&:hover{
				background: rgba(0, 0, 0, 0) !important;
				opacity: 1;
			}
						
			
						
			
		}
		
	}
		.submenu {
			.mat-nav-list{
				&:last-child {
						margin-bottom: 15px;
					}
			}
			.mat-list-item {
				font-size: 14px !important;
				line-height: 18px;
				font-weight: normal !important;
				font-family: 'Work_Sans';
				background-color: #CE002C;
				border-bottom: none;
				margin-bottom: 2px;
				&:hover {
						background-color: #CE002C !important;
						
					}
			}
						
		}
}

.submenu-wrap{
	.mat-list-item{
		margin-bottom: 1px !important;
	}
}
.mat-sidenav {
	width: 260px;

	.menu-inner {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		height: 100%;
	}

	@media screen and (max-width: 768px) {
		top: 0 !important;
	}

	.menu-top {
		margin: 45px 0;

		.menu-logo {
			max-width: 115px;
			margin: 0px auto 45px;
			display: flex;

			&:hover {
				opacity: 1;
			}

			img {
				max-width: 100%;
			}
		}
	}
}

.copyright-box{
	height: 65px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	p{
		margin-bottom: 0;
		font-size: 12px;
	}
}


// product 
.edit-link {
	text-decoration: underline;
	margin-bottom: 15px;
	text-decoration-color:#EA0029;
}



// purchase
.purchase-table{
	border: 1px solid #E6E6E6;
	padding: 20px 10px 45px;
	.home-btn{
		//width: 223px;
		height: 42px;
		background-color: #808080;
		
		span{
			font-size: 10px;
			font-family: 'Manifold';
			color: #ffffff;
		}
	}
		.button-res{
			@media screen and (min-width:960px) and (max-width:1100px) {
					flex-direction: column !important;
					.home-btn{
						margin-bottom: 12px;
					}
				}
		}
}

.whatsapp-icon-wrap {
	position: fixed;
	top: 50vh;
	right: 0;
	transform: translateY(-50%);
	z-index: 99;
	.whatsapp-stick-icon {
	  width: 50px;
	  height: 50px;
	}
  }



.custum-table{
	margin-top: 15px;
	overflow-x: auto;
	.mat-table{
		&.mobile-table {
				min-width: 900px;
			}
		tr.mat-row:not(.element-row):nth-child(2n-2){
			background-color: #E6E6E6;
		}
				
		.mat-header-cell{
			font-size: 12px;
			font-weight: 500;
			a{
				font-size: 12px;
					font-weight: 500 !important;
			}
		}
		tr.mat-header-row{
			height: 45px;	
		}
		.mat-cell{
			font-size: 12px;
			font-weight: 500;
			line-height: 14px;
			border: none;
			a{
				font-size: 12px;
				font-weight: 500 !important;
			}
			button{
				color: white;
				font-size: 10px;
				font-weight: 500 !important;
				padding: 10px;
					min-width: 10px;
					line-height: normal;
				&.mat-primary{
					background-color: #29ABE2;
				}
				&.mat-success{
					background-color:#39B54A;
				}
				&.download-btn{
					background-color: #ea0029;
					width: 95px;
					img{
						width: 10px;
					}
				}
				&.off-btn{
					background-color: gray;
					width: 95px;
					img{
						width: 10px;
					}
				}
			}
		}

		.listing-no{
			text-decoration: underline;
			text-decoration-color: #ea0029;
		}

		
	}
	
}

.sidenav-container {
	.mat-expansion-panel {
	  box-shadow: none !important;
	  background-color: #ea0029 !important;
	  color: white !important;
  
	  .mat-expansion-panel-header {
		height: auto !important;
		font-size: 11px !important;
		font-family: 'Manifold';
		padding: 10px 16px !important;
		align-items: center;
  
		.panel-icon {
		  width: 12px;
		  height: 12px;
		  margin-right: 15px;
		}
	  }
  
	  .mat-expansion-panel-header-title {
		color: white !important;
		align-items: center;
	  }
	}
  
	.mat-expansion-panel-header-description,
	.mat-expansion-indicator::after {
	  color: white !important;
	}
  
	.mat-expansion-indicator::after {
	  padding: 2px !important;
	}
  
	.mat-expansion-panel-body {
	  padding: 0 14px !important;
	}
  }
  



