
@import 'src/colors.scss';

@import '~@angular/material/theming';

@include mat-core();


$primary-palette: (400: $primary400, 500: $primary, contrast: (400: white, 500: white));
$accent-palette: (500: $accent, contrast: (500: white));


$primary: mat-palette($primary-palette, 500, 100, 900);
$accent: mat-palette($accent-palette, 500);
$background: mat-palette($mat-gray, 500, 100, 900);
$warn: mat-palette($mat-red);

$app-theme: mat-light-theme($primary, $accent, $warn);

@export $app-theme;

@include angular-material-theme($app-theme);
